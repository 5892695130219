import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Galery from "./pages/Galery";
import Error from "./pages/Error.js";
import Album from "./pages/Album.js";
import Girls from "./pages/Girls.js";
import TagGallery from "./pages/TagGallery.js";
import Categorys from './pages/Categorys.js';
import DMCA from './pages/DMCA.js';
import SingleCategorys from './pages/SingleCategorys.js';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Galery />} />
        <Route path="*" element={<Error />} />
        <Route path="/album/:id" element={<Album />} />
        <Route path="/tag/:id" element={<TagGallery />} />
        <Route path="/girls/:id" element={<Girls />} />
        <Route path="/categorys" element={<Categorys />} />
        <Route path="/categorys/:id" element={<SingleCategorys />} />
        <Route path="/DMCA" element={<DMCA />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);