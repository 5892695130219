import React from 'react';
import '../App.css';
import Header from './Header';
import Footer from './Footer';
import HeaderNav from './HeaderNav';

function DMCA() {

  return (
    <>
        <Header />
        <HeaderNav />
        <main>
        <section>
            <h1>Copyright and DMCA Policy</h1>
        </section>
        <article>
            <p>
                {'At hairygirls.online, we respect the intellectual property rights of third parties. As established in the Digital Millennium Copyright Act of 1998 ("DMCA"), our policy is to promptly respond to notices of alleged copyright infringement that comply with the DMCA and other applicable laws. If you believe that your copyrighted work has been reproduced, distributed, displayed, or otherwise used in a manner that constitutes copyright infringement, or if you represent someone who believes their work has been used in a way that constitutes copyright infringement, please send us a written notification through the following form: click here to access the form. We understand. If you are the owner of a website and wish to create a page to comply with DMCA requirements, here is an example of how you can structure that page:'}
            </p>
            <h2>Please include the following in your notification:</h2>
            <ul>
                <li>Specific identification of the copyrighted work you claim has been infringed.</li>
                <li>Specific identification of the material you claim is infringing the copyrighted work, including URLs or other information sufficient to allow us to locate the material.</li>
                <li>Sufficient contact information so that we may contact you, including your name and email address.</li>
                <li>A statement that you believe, in good faith, that the use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>
            </ul>
            <p>
                Upon receipt of a valid notification in accordance with the DMCA, we will respond by removing or disabling access to the allegedly infringing material and will take further action to notify the alleged infringer in accordance with the DMCA and other applicable laws.
                Please note that all content used here was collected from public networks and materials freely provided by the actresses themselves, with the intention of promoting their material to an even wider audience. We do not sell or receive any commission for any images displayed here.
                Our site is monetized through indirect advertising networks that are not correlated with the images displayed here.
            </p>
        </article>
        </main>
        <Footer />
    </>

  );
}

export default DMCA;
