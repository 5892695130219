import React from 'react';
import '../App.css';
import { useLocation } from 'react-router-dom';
import Header from './Header';
import { Link } from 'react-router-dom';
import HeaderNav from './HeaderNav';
import Footer from './Footer';

function SingleCategorys() {

  const location = useLocation();
  const pathname = location.pathname.substring(11);
  const jsonStars = require("../stars.json");
  const filteredData = jsonStars.filter(star => star.categorys.includes(pathname));
  console.log(filteredData);

  return (
    <div className="App">
      <Header />
      <HeaderNav />
      <main>
        <section>
            <h1>{`${pathname} Girls`}</h1>
        </section>
        <article>
          {filteredData.map((album) => (
            <a className='inside-image' key={album.girl} target="_blank" rel="noreferrer" href={`${album.fullAlbumAt}` }>
                <Link to={`/album/${album.girl}`}>
                    <img loading="lazy" alt={`${album.girl.replace(' ', /_/g)}`} className="main-pics" src={`${album.images[Math.floor(Math.random() * album.images.length)]}`} />
                    <p>{album.girl.replace(/_/g, ' ')}</p>
                </Link>
            </a>
          ))}
        </article>
      </main>
      <Footer />
    </div>
  );
}

export default SingleCategorys;
