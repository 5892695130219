import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

function Header() {

  return (
    <header>
      <Link to={"/"}>
        <div>
          <span className='logo_Hairy'>Hairy</span>
          <span className='logo_Girls'>Girls</span>
        </div>
      </Link>
    </header>
  );
}

export default Header;
