import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Tag = (props) => {
    return (
      <article className='tags'>
        {props.tags.map((tag, index) => (
            <Link key={index} to={`/tag/${tag}`}>
                <button
                    className="button-base"
                    target="_blank"
                >
                    {tag.replace(/_/g, ' ')}
                </button>
            </Link>
          ))}
      </article>
    );
};

Tag.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired
}
  
export default Tag;
