import React from 'react';
import '../App.css';
import { useLocation } from 'react-router-dom';
import Tag from './Tag';
import Header from './Header';
import HeaderNav from './HeaderNav';
import Footer from './Footer';

function Album() {

  const location = useLocation();
  const pathname = location.pathname.substring(7);
  const jsonStars = require("../stars.json");
  const filteredData = jsonStars.filter(item => item.girl === pathname);

  return (
    <div className="App">
      <Header />
      <HeaderNav />
      <main>
        <section>
            <h1>{filteredData[0].girl.replace(/_/g, ' ')}</h1>
            <a href={filteredData[0].fullAlbumAt} target="_blank" rel="noreferrer">
              <button className='button-base'>{`Girl profiles at ${filteredData[0].prod}`}</button>
            </a>
        </section>
        <article>
          {filteredData[0].images.map((image, index) => (
            <a target='_blank' key={index} href={image} rel="noreferrer">
              <img loading="lazy" alt={`${filteredData[0].girl.replace(/_/g, ' ')} ${index}`} className='main-pics' src={image} />
            </a>
          ))}
        </article>
        <section className='column'>
          <a href={filteredData[0].fullAlbumAt} target="_blank" rel="noreferrer">
            <button className='button-base'>{`Girl profiles at ${filteredData[0].prod}`}</button>
          </a>
          <p>{filteredData[0].text}</p>
          <p>{"Free images sourced from the actress's public social media profiles."}</p>
          <p>This is not a paid content leaked.</p>
          <Tag tags={filteredData[0].categorys} />
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Album;
