import React from 'react';
import '../App.css';
import { Link, useLocation } from 'react-router-dom';
import Header from './Header';
import HeaderNav from './HeaderNav';

function TagGallery() {

  const location = useLocation();
  const pathname = location.pathname.substring(5).toString();
  const jsonStars = require("../stars.json");
  const filteredData = jsonStars.filter(item => item.categorys.includes(pathname));

  return (
    <div className="App">
        <Header />
        <HeaderNav />
        <main>
            <section>
                <h1>Tag {pathname}</h1>
            </section>
            <article>
                {filteredData.map((album) => (
                    <a className='inside-image' key={album.girl.replace(/_/g, ' ')} target="_blank" rel="noreferrer" href={`${album.fullAlbumAt}` }>
                    <Link to={`/album/${album.girl}`}>
                        <img loading="lazy" alt={`${album.alt}`} className="main-pics" src={`${album.images[Math.floor(Math.random() * album.images.length)]}`} />
                        <p>{album.girl.replace(/_/g, ' ')}</p>
                    </Link>
                    </a>
                ))}
            </article>
        </main>
    </div>
  );
}

export default TagGallery;
