import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import Header from './Header';
import HeaderNav from './HeaderNav';
import Footer from './Footer';

function Galery() {
  const jsonStars = require("../stars.json");
  const randomSort = () => Math.random() - 0.5;
  const arrayRandon = [...jsonStars].sort(randomSort);

  return (
    <div className="App">
      <Header />
      <HeaderNav />

      <main>
        <section>
          <h1>Best Hairy Pornstars Pics</h1>
        </section>
        <article>
          {arrayRandon.map((album) => (
            <a className='inside-image' key={album.girl} target="_blank" rel="noreferrer" href={`${album.fullAlbumAt}` }>
              <Link to={`/album/${album.girl}`}>
                <img loading="lazy" alt={album.girl} className="main-pics" src={`${album.images[Math.floor(Math.random() * album.images.length)]}`} />
                <p>{album.girl.replace(/_/g, ' ')}</p>
              </Link>
            </a>
          ))}
        </article>
      </main>
      <Footer />
    </div>
  );
}

export default Galery;
