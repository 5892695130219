import React from 'react';
import '../App.css';

function Error() {

  return (
    <div className="App">
      <main>
        <h2>Ops!</h2>
        <h1>Você acessou um caminho vazio</h1>
      </main>
    </div>
  );
}

export default Error;
