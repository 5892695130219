import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';
import Header from './Header';
import HeaderNav from './HeaderNav';
import Footer from './Footer';


function Categorys() {
  const jsonStars = require("../stars.json");
  const jsonTags = require("../tags.json");
  const tagsArray = jsonTags[0].categorys;
  const randomSort = () => Math.random() - 0.5;
  const arrayRandon = [...jsonStars].sort(randomSort);
  const arrayBlauu = [];
  tagsArray.forEach(tag => arrayBlauu.push(arrayRandon.find(star => star.categorys.includes(tag))))

  return (
    <div className="App">
      <Header />
      <HeaderNav />
      <main>
        <section>
          <h1>Categorys</h1>
        </section>
        <article>
          {arrayBlauu.map((star, index) => (
              <a className='inside-image' key={index} target="_blank" rel="noreferrer" href={`${tagsArray[index]}` }>
              <Link to={`/categorys/${tagsArray[index]}`}>
                <img loading="lazy" alt={`${tagsArray[index]} Girls`} className="main-pics" src={`${star.images[Math.floor(Math.random() * star.images.length)]}`} />
                <p>{tagsArray[index].replace(/_/g, ' ')}</p>
              </Link>
            </a>
          ))}
        </article>
        <article>
          {arrayRandon.map((album) => (
            <a className='inside-image' key={album.girl} target="_blank" rel="noreferrer" href={`${album.fullAlbumAt}` }>
              <Link to={`/album/${album.girl}`}>
                <img loading="lazy" alt={`${album.girl.replace(/_/g, ' ')}`} className="main-pics" src={`${album.images[Math.floor(Math.random() * album.images.length)]}`} />
                <p>{album.girl.replace(/_/g, ' ')}</p>
              </Link>
            </a>
          ))}
        </article>
      </main>
      <Footer />
    </div>
  );
}

export default Categorys;
