import React from 'react';
import '../App.css';
import { useLocation } from 'react-router-dom';
import Tag from './Tag';
import Header from './Header';

function Girls() {

  const location = useLocation();
  const pathname = location.pathname.substring(5);
  const jsonStars = require("../stars.json");
  const filteredData = jsonStars.filter(item => item.girl === pathname);

  return (
    <div className="App">
      <Header />
      <main>
        <div className='line-container'>
          <h3>{filteredData[0].alt}</h3>
          <a href={filteredData[0].fullAlbumAt} target="_blank" rel="noreferrer">
            <button className='button-base'>{`Full access at ${filteredData[0].prod}`}</button>
          </a>
        </div>

        <div className='container'>
          {filteredData[0].images.map((image, index) => (
            <a target='_blank' key={index} href={image} rel="noreferrer">
              <img loading="lazy" alt={`${filteredData[0].alt} ${index}`} className='main-pics' src={image} />
            </a>
          ))}
        </div>
        <div className='container'>
          <div className='line-container'>
            <p>Model - {filteredData[0].girl}</p>
            <a href={filteredData[0].fullAlbumAt} target="_blank" rel="noreferrer">
              <button className="button-base">{`More of ${filteredData[0].girl}`}</button>
            </a>
            <p>Content by - {filteredData[0].prod}</p>
          </div>
          <div className='line-container'>
            <a href={filteredData[0].fullAlbumAt} target="_blank" rel="noreferrer">
              <button className="button-base">{`Get full access at ${filteredData[0].prod}`}</button>
            </a>
          </div>
        </div>
        <div className='container'>
          <Tag tags={filteredData[0].categorys} />
        </div>
      </main>
    </div>
  );
}

export default Girls;
