import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

function Footer() {

  return (
    <footer>
      <Link to={"/DMCA"}>
        <span>DMCA</span>
      </Link>
    </footer>
  );
}

export default Footer;
