import React from 'react';
import '../App.css';
import { Link } from 'react-router-dom';

function HeaderNav() {

  return (
    <nav>
      <Link to={"/"} >
        <div>
          <span>Girls</span>
        </div>
      </Link>
      <Link to={"/categorys"}>
        <div>
          <span>Categorys</span>
        </div>
      </Link>
    </nav>
  );
}

export default HeaderNav;
